import { navigate } from 'gatsby';
import { useContext, useEffect } from 'react';

import { UserStatus } from '@redsleeve/oilynx-domain';

import { SecurityContext } from '@lib/security';

export function useUserStatusGuard(redirectToProfile = false) {
  const { currentUser } = useContext(SecurityContext);

  useEffect(() => {
    if (currentUser?.status === UserStatus.Enabled) {
      redirectToProfile && navigate('/profile/');
    } else if (currentUser?.status === UserStatus.Disabled) {
      navigate('/profile/disabled/');
    } else if (currentUser?.status === UserStatus.AwaitingConfirmation) {
      navigate('/profile/under-review/');
    }
  }, [currentUser, redirectToProfile]);
}
