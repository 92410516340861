import Layout from '@layouts';
import { SecurityContext } from '@lib';
import { Link, navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useContext } from 'react';

import { useUserStatusGuard } from './helpers';

const AccountUnderReviewPage: FC<PageProps> = ({}) => {
  useUserStatusGuard(true);
  const { logout } = useContext(SecurityContext);

  return (
    <Layout
      url="/profile/under-review/"
      title="Account under review"
      disableIndex
    >
      <pre className="mt-10 whitespace-pre-wrap">
        Your account is being reviewed. We will contact you ASAP.
      </pre>
      <div className="flex flex-col space-y-4 p-4">
        <Link className="" to="/contact/">
          Contact Us
        </Link>
        <a
          className=""
          href="/logout/"
          onClick={async (event) => {
            event.preventDefault();
            await logout();
            return navigate('/');
          }}
        >
          Logout
        </a>
      </div>
    </Layout>
  );
};

export default AccountUnderReviewPage;
